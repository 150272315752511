





























































































































import { Component, Vue, Watch } from "vue-property-decorator";
import store from "../../../store";
import { AxiosResponse } from "axios";
import { UserApi } from "@/networks/UserApi";
import FieldUser from "@/views/components/Common/Filed.vue";
@Component({
  components: { FieldUser }
})
export default class Quick extends Vue {
  private data: any = {};

  private form = {
    q: "",
    order: null
  };
  private orderBy = [
    {
      text: "هیچکدام",
      value: null
    },
    {
      text: "تاریخ",
      value: "date"
    },
    {
      text: "تعداد تماس",
      value: "count"
    },
    {
      text: "تاریخ (نزولی)",
      value: "as-date"
    },
    {
      text: "تعداد تماس (نزولی)",
      value: "as-count"
    }
  ];
  private page = 1;
  mobile_list: any = [];
  get items() {
    if (this.mobile_list && this.mobile_list.length) {
      return this.mobile_list;
    } else {
      return this.data.data;
    }
  }
  mounted() {
    this.page = Number(this.$route.query.page || 1);
    if (this.$route.query.search) {
      this.form.q = this.$route.query.search as any;
    }
    if (window.innerWidth < 600) {
      document.addEventListener("scroll", this.HandleScrollMobile);
    }
    this.users();
  }
  timeout: any = null;
  HandleScrollMobile(e: any): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (
        e.target.scrollingElement.clientHeight +
          e.target.scrollingElement.scrollTop >=
        e.target.scrollingElement.scrollHeight - 100
      ) {
        this.page++;
      }
    }, 300);
  }
  public async users(): Promise<void> {
    try {
      if (this.page == 1) {
        this.mobile_list = [];
      }
      const res: AxiosResponse = await new UserApi().list({
        ...this.form,
        roleId: [1, 2, 4, 5, 6, 7],
        page: this.page
      });
      if (window.innerWidth < 600) {
        this.mobile_list = [...this.mobile_list, ...res.data.data.data];
        if (res.data.data.last_page == this.page) {
          document.removeEventListener("scroll", this.HandleScrollMobile);
        }
      }
      this.data = res.data.data;
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  public async deleteUser(id: string): Promise<void> {
    const confirm = window.confirm("آیا مطمئن به حذف کاربر هستید؟");
    if (!confirm) return;
    try {
      const res: AxiosResponse = await new UserApi().deleteUser(id);
      store.commit("showError", {
        message: res.data.message || "کاربر با موفقیت حذف شد",
        color: "danger"
      });
      await this.users();
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  @Watch("page")
  public onPageChanged() {
    this.$router.push({
      query: { page: String(this.page), search: this.form.q }
    });
    this.users();
  }

  @Watch("form.q")
  public onFormChanged() {
    this.$router.push({
      query: { page: String(this.page), search: this.form.q }
    });
    this.fetchEntriesDebounced();
  }

  private _timerId = 0;

  fetchEntriesDebounced(): void {
    // cancel pending call
    clearTimeout(this._timerId);

    // delay new call 500ms
    this._timerId = window.setTimeout(() => {
      this.page = 1;
      this.users();
    }, 700);
  }

  @Watch("form.order")
  public onForm2Changed() {
    this.users();
  }
}
