var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v("کاربران")]),_c('form',[_c('v-row',[_c('v-col',{staticClass:"px-12",attrs:{"cols":"12","md":"6","lg":"3"}},[_c('v-text-field',{attrs:{"filled":"","label":"جستجوی کاربر","placeholder":"نام، موبایل، کد ملی"},model:{value:(_vm.form.q),callback:function ($$v) {_vm.$set(_vm.form, "q", $$v)},expression:"form.q"}})],1),_c('v-col',{staticClass:"px-12",attrs:{"cols":"12","md":"6","lg":"3"}},[_c('v-select',{attrs:{"filled":"","label":"مرتب سازی بر اساس","items":_vm.orderBy},model:{value:(_vm.form.order),callback:function ($$v) {_vm.$set(_vm.form, "order", $$v)},expression:"form.order"}})],1)],1)],1),_c('v-simple-table',{attrs:{"fixed-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-right"},[_vm._v("#")]),_c('th',{staticClass:"text-right"},[_vm._v("نام")]),_c('th',{staticClass:"text-right"},[_vm._v("موبایل")]),_c('th',{staticClass:"text-right"},[_vm._v("شهر")]),_c('th',{staticClass:"text-right"},[_vm._v("سطح دسترسی")]),_c('th',{staticClass:"text-right"},[_vm._v("تشکیل پرونده آنلاین")]),_c('th',{directives:[{name:"can",rawName:"v-can",value:('calls'),expression:"'calls'"}],staticClass:"text-right"},[_vm._v("تعداد تماس ها")]),_c('th',{staticClass:"text-right"},[_vm._v("عملکرد")])])]),_c('tbody',_vm._l((_vm.items),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_c('a',{attrs:{"title":'برای مشاهده کلیه رویدادهای ' +
                    item.fname +
                    ' ' +
                    item.lname +
                    ' کلیک کنید. ',"href":'/events?q=&type=all&userId=' + item.id}},[_vm._v(_vm._s(item.fname + " " + item.lname))])]),_c('td',[_c('FieldUser',{attrs:{"user_id":item.id,"field":"mobile"}})],1),_c('td',[_vm._v(_vm._s(item.office ? item.office.name : ""))]),_c('td',_vm._l((item.roles),function(i){return _c('v-chip',{key:i.id,attrs:{"color":"green","x-small":""}},[_vm._v(_vm._s(i.name_fa)+" ")])}),1),_c('td',[_vm._v(" "+_vm._s(_vm._f("toDate")(item.created_at))+" ")]),_c('td',{directives:[{name:"can",rawName:"v-can",value:('calls'),expression:"'calls'"}]},[_c('a',{attrs:{"title":"برای مشاهده تماس ها کلیک کنید","href":'/calls?mentorId[]=' + item.id}},[_vm._v(_vm._s(item.mentor_call_count))])]),_c('td',[_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{directives:[{name:"can",rawName:"v-can",value:('edit user'),expression:"'edit user'"}],attrs:{"href":'/users/' + item.id}},[_c('v-list-item-title',[_vm._v("ویرایش")])],1),_c('v-list-item',{directives:[{name:"can",rawName:"v-can",value:('delete user'),expression:"'delete user'"}],on:{"click":function($event){return _vm.deleteUser(item.id)}}},[_c('v-list-item-title',[_vm._v("حذف")])],1),_c('v-list-item',{directives:[{name:"permission",rawName:"v-permission:any",value:(
                        'list event|full list event|own list event'
                      ),expression:"\n                        'list event|full list event|own list event'\n                      ",arg:"any"}],attrs:{"href":'/events?mentorId=' + item.id}},[_c('v-list-item-title',[_vm._v("مشاهده رویدادها")])],1)],1)],1)],1)])}),0)]},proxy:true}])}),(_vm.data.last_page)?_c('v-pagination',{staticClass:"hidden-xs-only",attrs:{"length":_vm.data.last_page,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }